import React, { useState } from 'react';
import BodyClassName from 'react-body-classname';
import SuccessImg from '../images/svg-gcbooks/success';

import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';
import { Link } from 'gatsby';

const SuccessDonationPage = () => {
  return (
    <BodyClassName className=" bg-dark success-donation-page ">
      <DefaultLayoutComponent title="Donation Success">
        <div className="site-main">
          <div className="  ">
            <div className="wrapper bg-white section  wrapper-xl pd-x-md">
              <div className="success-page-container">
                <div className="donation-success-img">
                  <SuccessImg />
                </div>
                <>
                  <h2 className="donation-success-msg">Your Giving has been successful</h2>
                  <p className="donation-success-descriptions">
                    Thank you for your generous contribution! Your <br /> support will make a meaningful difference in
                    our cause.
                  </p>
                  <div className="donation-success-container">
                    <Link
                      to="/give"
                      className="btn donation-success-button  btn-md btn-primary-ii btn-submit"
                      type="button"
                    >
                      EXPLORE MORE PROJECTS
                    </Link>
                  </div>
                  <p className="donation-success-footer">© Lineage Journey 2023.</p>
                </>
              </div>
            </div>
          </div>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export default SuccessDonationPage;
